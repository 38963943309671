@import "../../variables.scss";

.wrapper {
  width: 100%;
  border-radius: 10px;
  height: 50px;
  border: none;
  font-weight: bold;
  font-size: 1.2em;
}

.backgroundWhite{
  background-color: $white;
  color: $green;
}
.backgroundGreen{
  background-color: $green;
  color: $white;
}