@import "../../variables.scss";

.wrapper {
  width: 170px;
  height: 170px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.greenBackground {
  color: $white;
  background-color: $green;
}
.whiteBackground {
  color: $green;
  background-color: $white;
  border: 1px solid $green;
  position: relative;
}
.deleteIcon {
  position: absolute;
  top: 6%;
  right: 6%;
}

.reader {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.hidden {
  display: none;
}
