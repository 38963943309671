@import "../../variables.scss";

.wrapper {
  width: 100%;
}
.title {
  color: $grey;
  font-size: 0.8em;
  margin-right: 20px;
}
.textInput {
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px;
  border: 1px solid $green;
  outline: none;
  // box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.05);
}

.phoneInput {
  direction: ltr;
  input {
    width: 100%;
    border-radius: 15px;
    padding: 15px 20px;
    border: 1px solid $green !important;
    outline: none;
    // box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.05);
  }
}

.ltr{
  direction: ltr;
}