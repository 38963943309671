@import "../../variables.scss";

.wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  &_form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    padding: 6% 9%;
    &__title {
      color: $green;
      font-weight: bold;
      font-size: 1.5em;
    }
    &__carApprove {
      text-align: end;
      width: 90%;
      margin-left: 3%;
      // margin: 3%;
      text-decoration: underline;
      color: $green;
    }
    &__line {
      border: 1px solid #bbdb7a;
      height: 1px;
      width: 40%;
      margin-top: 20px;
    }
    &_qr {
      &__title {
        color: $green;
        font-size: 1.3em;
        font-weight: bold;
      }
    }
    button{
      margin-top: 20px;
    }
  }
}
