@import "../../variables.scss";

.wrapper {
  background-color: $green;
  color: $white;
  text-align: center;
  display: grid;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0 13%;
  align-content: center;
  grid-template-rows: 30% 15% 45% 10%;
  align-items: center;
  &_header{
    &__text{
      margin-top: 3%;
      font-weight: bold;
    }
  }
  &_text {
    &__title {
      font-size: 1.5em;
      font-weight: bold;
    }
    &__description {
      align-self: start;
    }
  }
}
