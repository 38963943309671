@import "../../../variables.scss";

.wrapper {
  padding: 6% 9% 0 9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_form {
    &_text {
      color: $green;
      text-align: center;
      &__title {
        font-size: 1.5em;
        font-weight: bold;
      }
      &__description {
      }
    }
    &__carApprove {
      text-align: end;
      margin: 3%;
      text-decoration: underline;
      color: $green;
    }
    &_combineInputs {
      display: flex;
      gap: 6%;
    }
    &_warning {
      display: flex;
      align-items: center;
      // justify-content: center;
      margin-top: 3%;
      &__text {
        margin-right: 10px;
        font-size: 0.9em;
        color: $green;
      }
    }
  }
}
