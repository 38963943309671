@import "../../variables.scss";

.wrapper {
  &_selector {
    width: 100%;
    height: 50px;
    display: grid;
    background-color: $white;
    border-radius: 15px;
    border: 1px solid $green;
    outline: none;
    // box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.05);

    select {
      // A reset of styles, including removing the default dropdown arrow
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 15px 20px;
      border-radius: 15px;
      background-color: transparent;
      border: none;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      background-image: linear-gradient(45deg, transparent 50%, $green 50%),
        linear-gradient(135deg, $green 50%, transparent 50%);
      background-position: 20px 50%, 25px 50%;
      background-size: 5px 5px;
      background-repeat: no-repeat;

      // Stack above custom arrow
      z-index: 1;

      // Remove dropdown arrow in IE10 & IE11
      // @link https://www.filamentgroup.com/lab/select-css.html
      &::-ms-expand {
        // display: none;
      }

      // Remove focus outline, will add on alternate element
      outline: none;
      color: black;
    }
    .select {
      display: grid;
      grid-template-areas: "select";
      align-items: center;
      position: relative;

      select,
      &::after {
        grid-area: select;
      }
      cursor: pointer;
    }

    // Interim solution until :focus-within has better support
    select:focus + .focus {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 2px solid;
      border-radius: inherit;
    }
  }
  .title {
    color: $grey;
    font-size: 0.8em;
    margin-right: 20px;
  }
}
