@import "../../../variables.scss";

.wrapper {
  padding: 6% 9% 0 9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_form {
    &_text {
      color: $green;
      text-align: center;
      margin-bottom: 6%;
      &__title {
        font-size: 1.5em;
        font-weight: bold;
      }
      &__description {
      }
    }
    &_checkbox {
      margin-top: 6%;
      display: flex;
      gap: 3%;
      align-items: center;
      &__input {
        width: 10%;
        min-height: 20px;
        margin: 0;
      }
      &__text {
        color: $green;
      }
    }
  }
}
