@import "../../../variables.scss";

.wrapper {
  padding: 3% 9%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    &_text {
      &__title {
        color: $white;
      }
      &__description {
        color: $white;
        // font-size: 1.3em;
        font-weight: bold;
      }
    }
    &_checkbox {
      display: flex;
      gap: 3%;
      align-items: center;
      &__input {
        width: 10%;
        min-height: 20px;
        margin: 0;
      }
      &__text {
        color: $green;
      }
  }
}
