@import "../../variables.scss";

.wrapper {
  background-color: $white;
  display: grid;
  grid-template-rows: 30% 60% auto;
  height: 100vh;
  height: -webkit-fill-available;
}

.dynamicHeight {
  height: auto;
}

.previewWrapper {
  background-color: $green;
  display: grid;
  grid-template-rows: 50% 50%;
  height: 100vh;
  max-height: -webkit-fill-available;
  &_header {
    position: relative;
    &_text {
      text-align: center;
      position: absolute;
      color: $white;
      font-size: 1.5em;
      font-weight: bold;
      bottom: 6%;
      left: 6%;
      &__number{
        font-size: 2.5em;
    } 
    :last-child{
          font-size: 1.5em;
      }
    }
  }
}
