@import "../../variables.scss";

.wrapper {
  display: flex;
  justify-content: center;
  direction: ltr;
  gap: 5px;
  input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    background: #fff;
    background-clip: padding-box;
    outline: none;
  }
}
.error {
  input {
    border-color: red;
  }
}
